import { useState } from 'react';
import { Slider, Text, Container } from '@mantine/core';
import { useDidUpdate } from '@mantine/hooks';
import { setLayerOpacity, useSettings } from '../../../store/settings';

export default () => {
  
  const [value, setValue] = useState(useSettings().layerOpacity*100);
  const [endValue, setEndValue] = useState(useSettings().layerOpacity*100);

useDidUpdate(() => {
    setLayerOpacity(endValue/100)
}
, [endValue])

  return (
   <div className='flex-1'>
        <Text>Opacity</Text>
      <Slider value={value} onChange={setValue} onChangeEnd={setEndValue} />
     
      </div>
  );
}
import Sidebar from "./Sidebar"
import Map from "./Map"
import dynamic from "next/dynamic"
import BottomSheet from "./BottomSheet"
import SearchBar from "./Sidebar/SearchBar"
import Filter from "./Sidebar/Filter"
const IsMobile = dynamic(import('../../providers/IsMobile'), { ssr: false })
export default () => {
    return (
        <div className="relative flex h-screen">
            <IsMobile mobile={<BottomSheet />} notMobile={<Sidebar />} />
            <IsMobile mobile={
                <div className="absolute top-2 left-2 right-2 justify-around z-40 flex">
                    <SearchBar />
                    <Filter />
                </div>
            }
                notMobile={<></>}
            />
            <Map />
        </div>
    )
}
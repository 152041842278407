import { api } from "./config";

export const searchPlot = (search: string) => api.post("/search", {}, { headers: { search: search } });

export const postAsk = (plotId: string, askPrice: number, dealerId: number, buy_rent_id: number, images: File[], description: string) => {
    let formdata = new FormData()

    formdata.append('plotId', plotId)
    formdata.append('askPrice', askPrice)
    formdata.append('dealerId', dealerId)
    formdata.append('status', '1')
    formdata.append('buyRentId', buy_rent_id)
    formdata.append('description', description)
    images?.forEach(image => {
        formdata.append('files', image)
    })

    return api.post("/plotAsks", formdata, { headers: { dealer: dealerId } })
};
export const newArrivals = (dealer: number) => api.get("/getNewArrivals", { headers: { dealer: dealer } });
export const updatePlotStatus = (id: string, status: string, admin: string) => api.post("/updatePlotStatus", { id, status }, { headers: { admin: admin } });

export const createBuyRentCat = (name: string, buyrent: string, imagerequired: number, descriptionrequired: number, admin: string) => api.post("/buyrentcat", { name, buyrent, imagerequired, descriptionrequired }, {
    headers: { admin: admin }
});
export const getBuyRentCat = () => api.get("/buyrentcat");

export const allAssociations = () => api.get("/associations");

export const getPlotDetails = (plotno:string, type:string,block:string) => api.get("/plots", { headers: { plotno: plotno, type: type, block: block } });

export const getHouses = () => api.get("/readHouses");
export const getFlats = () => api.get("/readFlats");

export const sellAssociation = (associationId:number,dealer: number) => {
    return api.post('/soldListing',null,{
        headers :{
            id:associationId,
            dealer: dealer 
        }
    })
}
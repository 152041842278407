

import Head from "next/head";
import Home from "../../templates/Home";







export default () => {

 
  


  return (
    <>
      <Head>

        <title>Jaidad Finder</title>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta http-equiv="X-UA-Compatible" content="IE=edge" />
        <meta name="msvalidate.01" content="5469381746EE215F82A8CADB059A729C" />
        <meta http-equiv="Cache-Control" content="Public" />
        <meta http-equiv="Pragma" content="no-cache" />
        <meta http-equiv="Expires" content="0" />
        <meta charSet="utf-8" />
        




      </Head>
      <Home />
     
    </>
  )
};

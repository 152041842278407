import { useMap } from "react-map-gl";

export default () => {
 
  const map = useMap().current

  const zoomIn = () => {
    map.flyTo({
      zoom: map.getZoom() + 1,
    })
  }
  const zoomOut = () => {
    map.flyTo({
      zoom: map.getZoom() - 1,
    })
  }
  return (
    <div className="flex flex-col bg-white rounded-xl shadow-3xl">
      <div className="py-2 text-lg px-3 hover:text-primary-100 cursor-pointer" onClick={zoomIn}>+</div>
      <div className="pt-1 pb-3 text-lg px-3 hover:text-primary-100 cursor-pointer" onClick={zoomOut}>_</div>
    </div>
  )
}


import { useEffect, useState } from 'react';
import { legacy_createStore } from 'redux';

const initialState = {
    selectedResult: {
        Id: '',
        Plot: '',
        Plot_Size: '',
        Block: '',
        fly: true
    }
}

const selectedResult = (state = initialState, action) => {
    switch (action.type) {
        case 'SELECT_RESULT':
            return {
                ...state,
                selectedResult: action.payload
            }
        default:
            return state;
    }
}

const ResultStore = legacy_createStore(selectedResult);

const selectResult = (result) => {
    
    ResultStore.dispatch({
        type: 'SELECT_RESULT',
        payload: result
    })
}

const detailsStore = legacy_createStore((state = {
    details: {}
}, action) => {
    switch (action.type) {
        case 'ADD_DETAILS':
            return {
                ...state,
                details: action.payload
            }
        default:
            return state;
        }
    }
)

const usePlotDetails = () => {
    const [plotdetails, setPlotDetails] = useState(detailsStore.getState());
    useEffect(() => {
        const unsub = detailsStore.subscribe(() => {
            setPlotDetails(detailsStore.getState())
        })
        return () => {
            unsub();
        }
    } , [])
    return plotdetails;
}

const addPlotDetails = (details) => {
    detailsStore.dispatch({
        type: 'ADD_DETAILS',
        payload: details
    })
}


export { selectResult, ResultStore,addPlotDetails,usePlotDetails };


import { useEffect, useState } from "react"
import { Source, Layer, useMap } from "react-map-gl"
import { getPlotDetails } from "../../../api/Plot";
import { addPlotDetails, ResultStore } from "../../../store/selectedResult"

export default () => {

    const map = useMap().current;
    const [state, setState] = useState(null)
    const [geom, setGeom] = useState(null)

    const getSel = () => ResultStore.getState()?.selectedResult?.properties

    useEffect(() => {
        
        ResultStore.subscribe(() => {
            getPlotDetails(getSel()?.N, getSel()?.T ,getSel()?.B).then(res => {
                if (res?.data) {
                    addPlotDetails(res.data)
                }
            }).catch(err => {
                console.log('getPlotDetails ERROR ', err)
            })
            setState(null)
            setGeom(ResultStore.getState()?.selectedResult?.geometry)
           
            if (ResultStore.getState()?.selectedResult?.geometry.type === 'MultiPolygon') {
                map?.flyTo({
                    center: ResultStore.getState()?.selectedResult?.geometry.coordinates?.[0][0][0],
                    zoom:map.getZoom() > 16 ? map.getZoom() : 16,
                })
            }else{
                map?.flyTo({
                    center: ResultStore.getState()?.selectedResult?.geometry.coordinates?.[0][0],
                    zoom: map.getZoom() > 16 ? map.getZoom() : 16,
                })
            }
            setTimeout(() => {
                setState(ResultStore.getState()?.selectedResult)
            },400)
        })
     

    }, [])


    return (
        <>
            {
                state !== null &&
                <Source
                    id='SelectedGeom'
                    data={geom}
                    type="geojson"
                >
                    <Layer
                        id='SelectedGeom'
                        type="line"
                        paint={{
                            "line-color": 'red',
                            'line-width': 2
                        }}
                    />
                </Source>
            }
        </>
    )
}
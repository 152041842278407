import { Plot } from "../../../interface/data"
import { ResultStore } from "../../../store/selectedResult"
import { MdShare } from "react-icons/md"
import { useState } from "react"

export default () => {

const [result, setResult] = useState(ResultStore.getState()?.selectedResult?.properties)

ResultStore.subscribe(() => {
    setResult(ResultStore.getState()?.selectedResult?.properties)
})



    return (
        <div className="relative py-2 px-2 flex w-full text-white md:shadow-3xl shadow-none">
            <div className="text-left">
            <h1 className="font-bold md:text-3xl text-sm">
              {
                   result?.T=='R'?"Gulberg Residencia":"Gulberg Greens"
              }
            </h1>
           <h2 className="font-semibold md:text-xl text-sm">
              Block : {' '}
              {
                    result?.B || ''
              }
            </h2>

           <h1 className="md:text-2xl text-base">
           {
               result?.N != undefined && "Plot : "+ result?.N
             } 
           </h1>
           <h1 className="md:text-lg text-sm">
           {
               result?.S && "Size : "+ result?.S
             } 
           </h1>
           </div>
           <div className="flex-1"/>
           <div className="text-white items-center flex mx-2 cursor-pointer justify-end flex-1 "> 
           <MdShare size={20} />
           </div>
        </div>
    )
    }
import { useEffect, useState } from "react";
import { createStore } from "redux";

const initialState = {
    layerOpacity: 1,
    layerVisibility: true,
    layerOrder: 0,
    colorMode: 'block',
    colorScale: 'quantile',
    colorScaleRange: [0, 100],
}

const reducer = (state = initialState, action: any) => {
    switch (action.type) {
        case 'setLayerOpacity':
            return {
                ...state,
                layerOpacity: action.payload
            }
        case 'setLayerVisibility':
            return {
                ...state,
                layerVisibility: action.payload
            }
        case 'setLayerOrder':
            return {
                ...state,
                layerOrder: action.payload
            }
        case 'setColorMode':
            return {
                ...state,
                colorMode: action.payload
            }
        case 'setColorScale':
            return {
                ...state,
                colorScale: action.payload
            }
        case 'setColorScaleRange':
            return {
                ...state,
                colorScaleRange: action.payload
            }
        default:
            return state
    }
}

const setLayerOpacity = (value) => {
    store.dispatch({
        type: 'setLayerOpacity',
        payload: value
    })
}

const setLayerVisibility = (value) => {
    store.dispatch({
        type: 'setLayerVisibility',
        payload: value
    })
}

const setLayerOrder = (value) => {
    store.dispatch({
        type: 'setLayerOrder',
        payload: value
    })
}

const setColorMode = (value) => {
    store.dispatch({
        type: 'setColorMode',
        payload: value
    })
}

const setColorScale = (value) => {
    store.dispatch({
        type: 'setColorScale',
        payload: value
    })
}

const setColorScaleRange = (value) => {
    store.dispatch({
        type: 'setColorScaleRange',
        payload: value
    })
}

const store = createStore(reducer)

const useSettings = () => {
    const [state, setState] = useState(store.getState())
    useEffect(() => {
        store.subscribe(() => {
            setState(store.getState())
        }
        )
    }
        , [])
    return state
}

export {
    setLayerOpacity,
    setLayerVisibility,
    setLayerOrder,
    setColorMode,
    setColorScale,
    setColorScaleRange,
    useSettings
}

import { Divider, ScrollArea } from "@mantine/core"
import Filter from "./Filter"
import Label from "./Label"
import PolyType from "./PolyType"
import PurchaseProperty from "./PurchaseProperty"
import SearchBar from "./SearchBar"
import { MdMail } from 'react-icons/md'
import PlotDetails from "./PlotDetails"
import { primaryColor } from "../../../api/config"

export default () => {

    return (
        <div className="w-1/3 flex flex-col shadow-3xl">
            <div className=" w-full shadow-3xl flex-col text-white"
            style={{
                backgroundColor : primaryColor
            }}
            >
                <div className=" justify-around py-4 flex">
                    <SearchBar />
                    <Filter />
                </div>
                <Label />
            </div>
            <ScrollArea>
                <PolyType />
                <Divider size={2} /><div className="flex flex-1 justify-center py-2 items-end">
                <div className="hover:text-primary-100 text-gray-700 cursor-pointer flex items-center">
                    <MdMail size={22} className='mx-2' /> ask@jaidadfinder.com
                </div>
            </div>
                <PlotDetails />
                <PurchaseProperty />
            </ScrollArea>
            
        </div>
    )
}
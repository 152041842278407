import { Button, Divider, ScrollArea } from '@mantine/core'
import { useDidUpdate } from '@mantine/hooks'
import { useEffect, useRef, useState } from 'react'
import { MdChevronLeft, MdChevronRight, MdMail } from 'react-icons/md'
import { BottomSheet, BottomSheetRef } from 'react-spring-bottom-sheet'
import 'react-spring-bottom-sheet/dist/style.css'
import { usePlotDetails } from '../../../store/selectedResult'
import Label from '../Sidebar/Label'
import PlotDetails from '../Sidebar/PlotDetails'
import PolyType from '../Sidebar/PolyType'
import PurchaseProperty from '../Sidebar/PurchaseProperty'
export default () => {
    const [open, setOpen] = useState(false)
    const details = usePlotDetails()

    useDidUpdate(() => {
        setOpen(true)
    }, [details])

    const sheetRef = useRef<BottomSheetRef>()
    function onDismiss() {
        setOpen(false)
    }
    return (
        <div>
            {!open && <div className='absolute text-sky-900 font-bold bg-white -ml-3 px-5 py-2 bottom-0 left-1/2 z-20' onClick={()=>setOpen(true)}>
                <MdChevronLeft className='rotate-90' />
            </div>}

            <BottomSheet className='z-50' open={open} ref={sheetRef}
                onDismiss={onDismiss}
                defaultSnap={({ snapPoints, lastSnap }) =>
                    lastSnap ?? Math.min(...snapPoints)
                }
                blocking={false}
                snapPoints={({ maxHeight }) => [maxHeight / 4, maxHeight * 0.6]}
                header={
                    <h1 className="flex items-center text-xl justify-center font-bold bg-gradient-to-r from-sky-800 to-cyan-700 ">
                        <Label />
                    </h1>
                }
               
            >
                <ScrollArea>
                    <PolyType />
                    <Divider size={2} />
                    <PlotDetails />
                    <PurchaseProperty />
                    <div className="flex flex-1 justify-center py-2 items-end">
                        <div className="hover:text-primary-100 text-gray-700 cursor-pointer flex items-center">
                            <MdMail size={22} className='mx-2' /> ask@7starestate.com
                        </div>
                    </div>
                </ScrollArea>
            </BottomSheet>
        </div>
    )
}
import { useForceUpdate } from "@mantine/hooks";
import { useEffect, useMemo } from "react";
import { Layer,Source } from "react-map-gl";
import { useMap } from "react-map-gl";
import { Plot } from "../../../interface/data";
import { useData } from "../../../store/data";
import { useSettings } from "../../../store/settings";
import { getBlockColor } from "../../../utils/BlockScale";
export default ({layerOpacity}) => {
    const map = useMap().current;
    const data = useData()?.data as GeoJSON.FeatureCollection;


    const memo = useMemo(() => {
     
        return {
            'type': 'FeatureCollection',
            'features':data?.features?.map((item) => {
               
                return item;
            }
            )}
    }, [data])
    
    return (<>{
        data?.features?.length > 0 &&
        <Source 
            id="polygons"
            type="geojson"
            data={memo}
            
        >
            <Layer
            beforeId={'waterway'}
                id="polygons"
                type="fill"
                paint={{
                    "fill-color": 'white',
                    "fill-opacity": 0.1,
                }}
            />
        </Source>
        }</>
    )
}

import {Source,Layer} from 'react-map-gl'

export default ({layerOpacity}) => {
    return (
        <Source type="raster" 
        tiles={['/tiles/{z}/{x}/{y}.png']}
            tileSize={256}
            maxzoom={19}
            
        >
            <Layer
                type="raster"
                beforeId={'waterway_intermittent'}
                id="raster-tiles"
                paint={{
                    'raster-opacity': layerOpacity
                }}
            />
        </Source>
    )
}



import { usePlotDetails } from "../../../store/selectedResult"
import { plotDetails } from "../../../interface/data"
import { Avatar, Text, Button, Paper } from '@mantine/core';
import { baseUrl, serverUrl } from "../../../api/config";
import { Carousel } from '@mantine/carousel';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
export default () => {
    const plotdetails = usePlotDetails()?.details as plotDetails
    return (
        <div className="flex flex-col w-full justify-center items-center p-4">
            <div className="w-full">
            <Carousel slideSize="70%" slideGap="md" >
                {plotdetails.associations?.map(association => (
                    <Carousel.Slide>
                        <div>
                            <Avatar src={`${serverUrl}agentsstorage/${association?.dealer?.image}`} size={120} radius={120} mx="auto" />
                            <Text align="center" size="lg" weight={500} mt="md">
                                {association?.dealer?.name?.toUpperCase()}
                            </Text>
                            <Text align="center" color="dimmed" size="sm">
                                {association?.dealer?.phone}
                            </Text>

                            <Button variant="default" fullWidth mt="md">
                                Send message
                            </Button>
                        </div>
                    </Carousel.Slide>
                ))}
            </Carousel>
            </div>
            <div className="flex-1 relative h-40 my-6 w-full">
              {plotdetails?.plot_asks?.length > 0 &&  <Visiography data={plotdetails} />}
            </div>
        </div>
    )
}

const Visiography = ({ data }: { data: plotDetails }) => {

   
    return (
        <ResponsiveContainer width="100%" height={200}>
            <LineChart
                margin={{
                    left:-20
                }}
                width={300}
                height={200}
                data={ data?.plot_asks?.map(i=>({
                    name:i?.createdAt?.slice(5,10),
                    price:i?.askPrice
                }))}
                
            >
                <CartesianGrid vertical={false} />
                <XAxis angle={-45} dataKey="name" tick={{fontSize:9}}  />
                <YAxis tick={{fontSize:9}} axisLine={false}/>
                <Tooltip />
                
                <Line type="monotone" dataKey="price" stroke="indigo" activeDot={{ r: 8 }} />
                
            </LineChart>
        </ResponsiveContainer>
        
    );
}
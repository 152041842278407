import { useEffect, useState } from "react"
import { getFlats, getHouses } from "../../../api/Plot"
import { PROPERTYTYPE } from "../../../interface/data"
import Image from "next/image"

export default () => {
    const [Houses, setHouses] = useState<PROPERTYTYPE>({})
    const [Flats, setFlats] = useState<PROPERTYTYPE>({})

    useEffect(() => {
        getHouses().then(res => setHouses(res.data))
        getFlats().then(res => setFlats(res.data))
    }, [])
    return (
        <div className="flex justify-around py-4">
            <div className="flex  flex-col items-center justify-center cursor-pointer">
                <Image src="/ic_house-colored.c431054.svg" alt="house" height={75} width={75} />
                <h3 className="text-sky-600 pt-2 text-lg font-bold">Houses</h3>
                <h3 className="text-gray-600 py-2 text-xs font-bold">Average Purchase Price</h3>
                <h2>{Math.ceil((Houses?.listing_types?.reduce((a,b)=>a + b?.plot_ask?.askPrice,0)/Houses?.listing_types?.length)/1000)}K PKR</h2>
            </div>
            <div className="flex flex-col  items-center justify-center cursor-pointer">
                <Image src="/ic_apartment-colored.da52bda.svg" alt="house" height={75} width={75} />
                <h3 className="text-sky-600 pt-2 text-lg font-bold">Flats</h3>
                <h3 className="text-gray-600 py-2 text-xs font-bold">Average Purchase Price</h3>
                <h2>{Math.ceil((Flats?.listing_types?.reduce((a,b)=>a + b?.plot_ask?.askPrice,0)/Flats?.listing_types?.length)/1000)}K PKR</h2>
            </div>
        </div>
    )
}
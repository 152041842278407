
import { useEffect, useMemo } from "react"

import { createStore } from "redux"
import { ReactSearchAutocomplete } from 'react-search-autocomplete'
import { useData } from "../../../store/data"
import { selectResult } from "../../../store/selectedResult"


const searchstore = createStore((state = '', action) => {
    switch (action.type) {
        case 'SetState':
            return action.payload
        case 'ClearState':
            return ''
    }

})


const setSearchInput = (value) => {
    searchstore.dispatch({
        type: 'SetState',
        payload: value
    })
}

const useSearchInput = () => {
    const [value, setValue] = useState('')
    useEffect(() => {
        searchstore.subscribe(() => {
            setValue(searchstore.getState())
        }
        )
    }
        , [])
    return value
}

export default () => {

    const data = useData()?.data?.features?.map((item) => {
        
        item.properties.name = `${item?.properties?.T} ${item?.properties?.N} ${item?.properties?.B} `
        item.properties.geometry = item?.geometry
        return item.properties
    }
    )


    

    const handleOnSelect = (item) => {
       var feature = {
            type: 'Feature',
            geometry: item?.geometry,
            properties: item,
            fly:true
       }
      
        selectResult(feature)
    }

   
    const formatResult = (item) => {
        return (
           
                <span key={item.name} style={{ display: 'block', textAlign: 'left' }}>{item.name}</span>
           
        )
    }

    return (
        <div className="flex-1 mx-2 flex">
            <div className="flex-1 relative z-50">
                <ReactSearchAutocomplete
                    items={data}

                    onSelect={handleOnSelect}
    
                    autoFocus
                    placeholder="Search (Block Plot)"
                    styling={{
                        borderRadius:'5px'
                    }}
                    formatResult={formatResult}
                />
            </div>
            {/* <input placeholder="Search"
                className="h-12 flex-1 rounded-l px-2 focus:outline-none"
                onChange={(e) => {
                    setSearchInput(e.target.value)
                }}
            /> */}
            {/* <span className="flex justify-center items-center h-12  aspect-square rounded-r bg-white text-sky-900 cursor-pointer">
                <GrClose size={15} />
            </span> */}
            {/* <SearchResult /> */}
        </div>
    )
}


const SearchResult = () => {

    return (
        <div className="absolute top-16 mt-1 shadow-3xl w-80 h-36 bg-white">

        </div>
    )
}
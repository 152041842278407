import { Avatar, Divider } from "@mantine/core"


export default () => {



    return (
        <div className="flex flex-col justify-center items-center mt-4">
            <b className="font-bold text-base ">
                Would you like to purchase a property?
            </b>
            <p className="px-4 text-sm text-center italic py-4">
                "We evaluate your property free of charge on site
                and advise you on the sale without obligation."
            </p>
            <div className="justify-center flex flex-col items-center">
                <Avatar radius={"xl"} size={'xl'} />
                <b className="py-2">Jaidad Finder</b>
                <p className="text-xs font-light text-gray-800">Titanium Real Estate agency in Gulberg Islamabad</p>
                <div className="shadow-2sm background cursor-pointer bg-primary-100 text-white px-5 py-3 rounded font-bold mt-5">
                    Book a free review
                </div>
            </div>
        <Divider size={2}/>
        </div>
    )
}
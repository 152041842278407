import { getBlockColor } from "../../../utils/BlockScale"

export default () => {

    const items = ['A', 'B', 'C', 'D', 'E', 'Apartments Site', 'Executive', 'Others']


    return (
        <div className="p-2 flex justify-center flex-col bg-white shadow-3xl">
            <h2 className="flex flex-1 text-center justify-center font-bold">Blocks</h2>
            <div className="text-right">
                <div className="flex flex-row">
                    <div style={{backgroundColor:'#ffe28c'}} className='p-2 rounded-full h-2 w-2 mx-2'/>
                    <div>10K</div>
                </div>
                <div className="flex flex-row">
                    <div style={{backgroundColor:'#ffb061'}} className='p-2 rounded-full h-2 w-2 mx-2'/>
                    <div>5K</div>
                </div>
                <div className="flex flex-row">
                    <div style={{backgroundColor:'#ff7f00'}} className='p-2 rounded-full h-2 w-2 mx-2'/>
                    <div>4K</div>
                </div>
            {/* {
                items.map(o => (
                    <div key={o + 'ef'} className='flex flex-row'>
                        <div style={{ backgroundColor: getBlockColor(o) }} className='p-2 rounded-full h-2 w-2 mx-2'/>
                        <div>
                            {o}
                        </div>
                    </div>
                ))
            } */}
</div>
        </div>
    )
}
import { GoSettings } from 'react-icons/go'
import { useModals } from '@mantine/modals';
import SettingsModal from '../SettingsModal';
export default () => {
    const modals = useModals();
    
  const openModal = () => modals.openConfirmModal({
    title: 'Please confirm your action',
    children: (
      <SettingsModal />
    ),
    labels: { cancel:'Cancel',confirm:'Save' },
    onCancel: () => console.log('Cancel'),
    onConfirm: () => console.log('Confirmed'),
  });
    return (
        <div
            onClick={openModal}
            className="bg-white flex items-center md:shadow-none shadow-2xl mr-2 justify-center cursor-pointer text-sky-900 aspect-square h-12 rounded">
            <GoSettings size={20} />
        </div>
    )
}
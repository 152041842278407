import { Source, Layer } from 'react-map-gl'

export default ({ sattelite }) => {

    return (
        <>
            {
                sattelite &&
                <Source
                    id='Basetiles'
                    type='raster'
                    tiles={['https://mt0.google.com/vt/lyrs=s&hl=en&x={x}&y={y}&z={z}']}
                    tileSize={256}
                    maxZoom={19}
                >
                    <Layer
                     beforeId={'waterway'}
                        id='Basetileslyr'
                        type='raster'
                        source='Basetiles'
                    />
                </Source>
            }
        </>

    )
}

import axios from "axios"

export const baseUrl = 'https://jaidadfinder.com/rest/'	
export const serverUrl = 'https://jaidadfinder.com/'
export const primaryColor = '#ad272e'	
// export const baseUrl = 'http://localhost:5000/'							
export const api = axios.create({
    baseURL: baseUrl,
    headers: {
        "xu":"ehfwehohoqf83hf28f083fh8210gh1083g813h"
    }

})
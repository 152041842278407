
import { BackgroundImage } from '@mantine/core';
import { useDidUpdate, useForceUpdate } from '@mantine/hooks';
import maplibregl from 'maplibre-gl';
import 'maplibre-gl/dist/maplibre-gl.css';
import Image from 'next/image';
import { useCallback, useState } from 'react';

import Map, { GeolocateControl } from 'react-map-gl';
import { Plot } from '../../../interface/data';
import { selectResult } from '../../../store/selectedResult';
import { useSettings } from '../../../store/settings';
import Legend from './Legend';
import Polygons from './Polygons';
import Sattelite from './Sattelite';
import SelectedLayer from './SelectedLayer';
import Tile from './Tiles';
import ZoomButtons from './zoomButtons';

const basic = "https://api.maptiler.com/maps/basic/style.json?key=HJjPE82Acf9Cxp7HurOp"


export default () => {

    const settings = useSettings()
    const render = useForceUpdate()

    useDidUpdate(() => {
        render()
    },[settings])
    
    const [mapStyle, setMapStyle] = useState(false);

    const [hoverInfo, setHoverInfo] = useState(null);
    const onHover = useCallback(event => {
        const {
            features,
            point: { x, y }
        } = event;
        const hoveredFeature = features && features[0];

        setHoverInfo(hoveredFeature && { feature: hoveredFeature, x, y });
    }, []);
    const onClick = useCallback(event => {
        const {
            features,
            point: { x, y }
        } = event;
        const hoveredFeature = features && features[0];
        var props = hoveredFeature
        props.fly = false
       
        selectResult(props);

    }, []);

    const geolocateControlRef = useCallback((ref) => {
        if (ref) {
            ref.trigger();
        }
    }, []);

    return (
        <div className='relative flex-1 h-screen'>
            <div className='z-50 absolute left-3 top-3 h-20 aspect-square md:mt-1 mt-10 '>
                <div className='relative md:w-24 md:h-24 w-12 h-12'>
                <Image src='/jaidadfinderlogo.png' layout='fill' objectFit='contain' />
                </div>
            </div>
            <div
            className='bg-white  absolute left-3 md:bottom-3 bottom-auto shadow-3xl md:h-28 h-14 rounded md:w-28 w-14 md:top-auto top-24 cursor-pointer z-20'
            >
            <Image 
            onClick={() => {
               setMapStyle(!mapStyle)
            }}
            src={mapStyle ? '/Basic.jpg' : '/Sat.jpg'} layout='fill' objectFit='cover' />
            
            
            </div>
            <Map
                initialViewState={{
                    longitude: 73.22010448950878,
                    latitude: 33.61510628124807,
                    zoom: 13
                }}
                attributionControl={false}
                // sattelite 
                mapStyle={basic}
                
                onLoad={(map) => {

                }}

                interactiveLayerIds={['polygons']}
                onMouseMove={onHover}
                onMouseOut={() => setHoverInfo(null)}
                onClick={onClick}
                mapLib={maplibregl}
                style={{ position: 'absolute', width: '100%', height: '100%' }}
            >
                {hoverInfo && (
                    <div className="absolute md:block hidden bg-slate-900 text-white p-1 ml-1 mt-1" style={{ left: hoverInfo.x, top: hoverInfo.y }}>

                        <h3>Block : {hoverInfo.feature.properties?.B}</h3>
                        <h3>Plot : {hoverInfo.feature.properties?.N}</h3>

                    </div>
                )}
                <Polygons layerOpacity={settings.layerOpacity} />
                <SelectedLayer />
                <div className='absolute right-2 top-16'>
                    <ZoomButtons />
                </div>
                    <div className='absolute right-3 bottom-3'>
                        <Legend />
                    </div>
                    <Sattelite sattelite={mapStyle} />
                <GeolocateControl
                style={{marginTop:'150px',borderRadius:'9999px'}}
                
                    showAccuracyCircle={false}
                    showUserLocation={true}

                    ref={geolocateControlRef} />
                <Tile layerOpacity={settings.layerOpacity}/>

            </Map>
        </div>
    )
}